import React, { useEffect } from 'react';
import './AdsenseDisplay.css';

export const AdsenseDisplay = (props) => {

    const { dataAdSlot } = props;

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            console.error('Error loading adsbygoogle:', e);
        }
    }, []);

  return (
    <div className='anuncio'>
        <ins className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-1255089201839493"
            data-ad-slot={dataAdSlot}
            data-ad-format="auto"
            data-full-width-responsive="true">
        </ins>
    </div>
  )
}
